
    var riot = require('riot')
     require('riot-kit/lib/dateformat')

riot.tag2('twitter-script', '', '', '', function(opts) {
    !function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (!d.getElementById(id)){
        js = d.createElement(s);
        js.id = id;
        js.src = "https://platform.twitter.com/widgets.js";
        fjs.parentNode.insertBefore(js,fjs);
      }
    }(document, 'script', 'twitter-wjs');

});
riot.tag2('twitter', '<twitter-script></twitter-script><a href="{opts.url}" data-chrome="{opts.chrome}" data-widget-id="{opts.widgetid}" height="{opts.height}" class="twitter-timeline">Tweets by {opts.handle}</a>', '', '', function(opts) {
});
riot.tag2('twitter-list', '<virtual each="{tweet,i in tweets}"><yield></yield></virtual>', '', '', function(opts) {
var resource;

console.log('loading tweets');

resource = require('riot-kit/utils/apiResource.coffee');

this.on('mount', function() {
  var params;
  params = opts.count ? '?count=' + opts.count : '';
  this.twitterApi = new resource((opts.src || '/duocms/api/tweets') + params);
  return this.twitterApi.get((function(_this) {
    return function(data) {
      return _this.onload(data);
    };
  })(this));
});

this.onload = (function(_this) {
  return function(data) {
    console.log('loading..');
    _this.tweets = data.map(function(tweet) {
      var ref;
      if ((ref = tweet.urls) != null) {
        ref.forEach(function(url) {
          return tweet.text = tweet.text.replace(url.url, '<a href="' + url.url + '">' + url.url + '</a>');
        });
      }
      tweet.created_at = new Date(tweet.created_at);
      return tweet;
    });
    if (opts.loaded) {
      opts.loaded(_this.tweets);
    }
    console.log('loaded', _this.tweets);
    return _this.update();
  };
})(this);
});
riot.tag2('twitter-feed', '<div class="carousel slide"> <ol if="{opts.showDots}" class="carousel-indicators"> <li each="{tweet,i in tweets}" onclick="{parent.goto}" class="dot {active:i==parent.active}"></li> </ol> <ul riot-tag="twitter-list" riot-src="{opts.src}" count="{opts.count}" loaded="{loaded}" class="carousel-inner tweet_list"> <li class="item {i%2 ? \'tweet-even\' : \'tweet-odd\'} {parent.parent.slideclass[i]}"><span riot-tag="raw" content="{tweet.text}" class="tweet_text"></span></li> </ul> </div>', 'twitter-feed { display: block; position: relative; } twitter-feed .carousel-inner .item { border-bottom: 10px solid #00374c !important; -webkit-transition: opacity 2s; -moz-transition: opacity 2s; -o-transition: opacity 2s; -ms-transition: opacity 2s; transition: opacity 2s; opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; filter: alpha(opacity=0); margin-bottom: 0; z-index: 5; } twitter-feed .carousel-inner .item.active.left { opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; filter: alpha(opacity=0); } twitter-feed .carousel-inner .item.active, twitter-feed .carousel-inner .item.next.left { -webkit-transition: opacity 1s; -moz-transition: opacity 1s; -o-transition: opacity 1s; -ms-transition: opacity 1s; transition: opacity 1s; opacity: 1; -ms-filter: none; filter: none; } twitter-feed .carousel-inner .item.next, twitter-feed .carousel-inner .item.active.left { left: 0; -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); } @media screen and (min-width: 768px) { twitter-feed .carousel-indicators { bottom: 0px; } }', '', function(opts) {
this.interval = null;

this.animating = false;

this.slideclass = ['active'];

this.loaded = (function(_this) {
  return function(tweets) {
    _this.tweets = tweets;
    console.log('loaded twitter-feed');
    _this.active = 0;
    _this.total = _this.tweets.length;
    if (_this.opts.autoplay != null) {
      _this.interval = setInterval(function() {
        return _this.next();
      }, _this.opts.autoplay);
    }
    return _this.update();
  };
})(this);

this.next = (function(_this) {
  return function() {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.active;
    _this.active++;
    if (_this.active >= _this.total) {
      _this.active = 0;
    }
    return _this.slidenext();
  };
})(this);

this.slidenext = (function(_this) {
  return function() {
    _this.animating = true;
    _this.slideclass[_this.previndex] = 'active left';
    _this.slideclass[_this.active] = 'next';
    _this.update();
    setTimeout(function() {
      _this.slideclass[_this.active] = 'next left';
      return _this.update();
    }, 20);
    return setTimeout(function() {
      _this.slideclass[_this.active] = 'active';
      _this.slideclass[_this.previndex] = '';
      _this.update();
      return _this.animating = false;
    }, 700);
  };
})(this);

this.prev = (function(_this) {
  return function() {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.active;
    _this.active--;
    if (_this.active < 0) {
      _this.active = _this.total - 1;
    }
    return _this.slideprev();
  };
})(this);

this.slideprev = (function(_this) {
  return function() {
    _this.animating = true;
    _this.slideclass[_this.previndex] = 'active right';
    _this.slideclass[_this.active] = 'prev';
    _this.update();
    setTimeout(function() {
      _this.slideclass[_this.active] = 'prev right';
      return _this.update();
    }, 20);
    return setTimeout(function() {
      _this.slideclass[_this.active] = 'active';
      _this.slideclass[_this.previndex] = '';
      _this.update();
      return _this.animating = false;
    }, 700);
  };
})(this);

this.goto = (function(_this) {
  return function(e) {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.active;
    _this.active = e.item.i;
    if (_this.active < _this.previndex) {
      _this.slideprev();
    } else {
      _this.slidenext();
    }
    return _this.update();
  };
})(this);
});
    
  