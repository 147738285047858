
    var riot = require('riot')
    
riot.tag2('migrationbar', '<div class="container"> <div class="row"> <div class="col-xs-12"> <div if="{!saving}" class="btn-toolbar"> <button onclick="{updatePage}" class="btn btn-danger btn-sm">Update Page</button> <button onclick="{updateSection}" class="btn btn-danger btn-sm">Update Section Pages</button> <button onclick="{updateProduct}" class="btn btn-warning btn-sm">Update Product</button> <button onclick="{updateProducts}" class="btn btn-warning btn-sm">Update Section Products</button> </div> <div if="{saving}"> <p> <span class="fa fa-refresh fa-spin fa-fw"> </span>Updating... please be patient</p> </div> </div> </div> </div>', 'migrationbar { position: fixed; bottom: 0; left: 0; right: 0; padding: 0 0 10px 0; display: block; background: rgba(0,0,0,0.5); z-index: 1002; }', '', function(opts) {
this.saving = false;

this.updatePage = (function(_this) {
  return function() {
    var oReq;
    _this.update({
      saving: true
    });
    oReq = new XMLHttpRequest();
    oReq.open("GET", "/duocms/api/import/page?path=" + window.location.pathname);
    oReq.addEventListener("load", function() {
      return window.location.reload();
    });
    return oReq.send();
  };
})(this);

this.updateSection = (function(_this) {
  return function() {
    var oReq;
    _this.update({
      saving: true
    });
    oReq = new XMLHttpRequest();
    oReq.open("GET", "/duocms/api/import/section?path=" + window.location.pathname);
    oReq.addEventListener("load", function() {
      return window.location.reload();
    });
    return oReq.send();
  };
})(this);

this.updateProduct = (function(_this) {
  return function() {
    var oReq;
    _this.update({
      saving: true
    });
    oReq = new XMLHttpRequest();
    oReq.open("GET", "/duocms/api/import/product?path=" + window.location.pathname);
    oReq.addEventListener("load", function() {
      return window.location.reload();
    });
    return oReq.send();
  };
})(this);

this.updateProducts = (function(_this) {
  return function() {
    var oReq;
    _this.update({
      saving: true
    });
    oReq = new XMLHttpRequest();
    oReq.open("GET", "/duocms/api/import/products?path=" + window.location.pathname);
    oReq.addEventListener("load", function() {
      return window.location.reload();
    });
    return oReq.send();
  };
})(this);
});
    
  