window.riot = riot = require 'riot'
window.jQuery = $ = require 'jquery'

require 'riot-kit/lib/slideshow'
require "riot-kit/lib/img_loader"
require 'riot-kit/lib/form'
require 'riot-kit/lib/modal'
require 'riot-kit/lib/form'
require 'riot-kit/lib/ecommerce'
require 'riot-kit/lib/pagination'
require 'riot-kit/lib/dateformat'
require 'riot-kit/lib/booking-calendar'
require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/gmap'
require('riot-kit/lib/auth/forgot-password')

require './tags/migrationbar.tag'
require './tags/loginbar.tag'
require './tags/ecommercebar.tag'
require './tags/navigation.tag'
require './tags/mydetails.tag'
require './tags/mypassword.tag'
require './tags/signup.tag'
require './tags/tags-input.tag'
require './tags/comments_input.tag'
require './tags/comments_output.tag'
require './tags/recaptcha.tag'
require './tags/pricetag.tag'
require './tags/eventcalendar.tag'
require './tags/twitter.tag'
require './libs/slideshow.coffee'
require './css/bootstrap/js/bootstrap.min.js'

riot.mount('*')
document.addEventListener 'duocms:updated', -> riot.mount('slideshow,gmap')


# jQuery(document).ready(function() {
#   jQuery('form#contactForm').submit(function() {
#     jQuery('form#contactForm .error').remove();
#     var hasError = false;
#     jQuery('.requiredField').each(function() {
#       if(jQuery.trim(jQuery(this).val()) == '') {
#         var labelText = jQuery(this).prev('label').text();
#         jQuery(this).parent().append('<div class="error">* Require</div>');
#         hasError = true;
#       } else if(jQuery(this).hasClass('email')) {
#         var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
#         if(!emailReg.test(jQuery.trim(jQuery(this).val()))) {
#           var labelText = jQuery(this).prev('label').text();
#           jQuery(this).parent().append('<div class="error">* Require</div>');
#           hasError = true;
#         }
#       }
#     });
#     if(!hasError) {
#       jQuery('form#contactForm li.buttons button').fadeOut('normal', function() {
#         jQuery(this).parent().append('Please Wait...');
#       });
#       var formInput = jQuery(this).serialize();
#       jQuery.post(jQuery(this).attr('action'),formInput, function(data){
#         jQuery('form#contactForm').slideUp("fast", function() {          
#           jQuery(this).before('<p class="thanks">Thanks! Your email was sent</p>');
#         });
#       });
#     }
#     return false;
#   });
# });    


